import React, { useState } from 'react';
import { FileUploader } from '../components/FileUploader';
import { Button, Form } from 'react-bootstrap';

function TextImageToImage() {
	const [text, setText] = useState("");
	const [fileName, setFileName] = useState("");
	const [url, setURL] = useState("");
	const [generatedImageURL, setGeneratedImageURL] = useState("");
	const [selectedFile, setSelectedFile] = useState(null); // new state

	const handleTextChange = (event) => {
		setText(event.target.value);
	};

	const handleFile = (file) => {
		setGeneratedImageURL(null)
		setFileName(file.name);
		console.log(URL.createObjectURL(file));
		setURL(URL.createObjectURL(file));
		setSelectedFile(file);
	};

	const handleGenerateImage = () => {
		console.log('Generating image from text');
		console.log(text);

		console.log(url);

		if (text.trim().length > 0 && selectedFile) {

			const formData = new FormData();
			formData.append('img', selectedFile);
			formData.append('text', text);


			// Make an API call here, for example using fetch or axios
			fetch('https://asia-south1-cloud-medley.cloudfunctions.net/text-image-to-image', {
				method: 'POST',
				body: formData,
			})
				.then(response => response.json())
				.then(data => {
					// Handle the response from the API, update generatedImageURL if needed
					console.log('API response:', data);
					setGeneratedImageURL(data.img); // Assuming the API returns a generated image URL
				})
				.catch(error => {
					console.error('Error generating image from text:', error);
				});
		} else {
			console.warn('No text or Image provided to generate image.');
		}
	};

	return (
		<div>
			<div className="div-box">
				{/* Input field for text */}
				<div>
					<Form.Control
						type="text"
						placeholder="Enter text to generate image"
						value={text}
						onChange={handleTextChange}
					/>
				</div>

				<div><FileUploader handleFile={handleFile} /></div>

				{selectedFile ?
					<div className="div-box">
						<div><img src={url} alt='' className="img-box" /><p>Original</p></div>
					</div>
					: null}

				{/* Button to trigger image generation */}
				{(text.trim().length > 0 && selectedFile) && <div><Button variant="primary" onClick={handleGenerateImage}>Generate Image</Button></div>}
			</div>

			{/* Display generated image if available */}
			{generatedImageURL && (
				<div className="div-box">
					<div><p>Generated Image</p></div>
					<div><img src={generatedImageURL} alt='' className="img-box" /></div>
				</div>
			)}
		</div>
	);
}

export default TextImageToImage;