import { useNavigate } from 'react-router-dom'
import { Container, Card, CardMedia, CardContent, CardActionArea, Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: 20,
  margin: '0 auto', display: "flex",
  backgroundColor: '#ff6d75',
  '&:hover': {
    backgroundColor: '#ff6d75',
  },
}));

export default function Home() {
  let navigate = useNavigate();

  const refineImage = () => {
    console.log('in refineImage')
    navigate('/refine', { state: { x: 1, y: 2 } })
  }

  const swapFace = () => {
    console.log('in swapFace')
    navigate('/swap', { state: { x: 1, y: 2 } })
  }

  const textToImage = () => {
    console.log('in textToImage')
    navigate('/text-image', { state: { x: 1, y: 2 } })
  }

  // const textImageToImage = () => {
  //   console.log('in textImageToImage')
  //   navigate('/textImageToImage', { state: { x: 1, y: 2 } })
  // }

  const removeBackground = () => {
    console.log('in removeBackground')
    navigate('/clear-background', { state: { x: 1, y: 2 } })
  }

  const imageObservation = () => {
    console.log('in imageObservation')
    navigate('/desc-image', { state: { x: 1, y: 2 } })
  }

  const imageLocationObservation = () => {
    console.log('in imageLocationObservation')
    navigate('/desc-location-image', { state: { x: 1, y: 2 } })
  }

  const imageChat = () => {
    console.log('in image chat')
    navigate('/image-chat', { state: { x: 1, y: 2 } })
  }

  const travelBot = () => {
    console.log('in Travel Bot')
    navigate('/travel-bot', { state: { x: 1, y: 2 } })
  }

  const quizBot = () => {
    console.log('in Quiz Bot')
    navigate('/quiz-bot', { state: { x: 1, y: 2 } })
  }

  return (
    <main>
      <Container sx={{ py: 3 }} maxWidth="lg">
        <Grid container spacing={2} xs={12}>
          <Grid item key={0} xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ border: "none", boxShadow: "none" }}>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div" style={{ color: "#770737" }}>
                  Imagine - Conceptualize - Realize. Repixel Artificial Intelligence.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item key={1} xs={12} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={refineImage}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/refine-5.jpg')}
                  alt="Refine Image"
                />
                <CardContent>
                  <ColorButton variant='contained'>Refine Image</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Enhance the clarity of your images effortlessly
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={2} xs={12} md={4} style={{ display: 'flex' }}>

            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={swapFace}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/swap-1.jpg')}
                  alt="Face Swap"
                />
                <CardContent>
                  <ColorButton variant='contained'>Face Swap</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Swap faces between images seamlessly
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={textToImage}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/text2img.jpg')}
                  alt="Text to Image"
                />
                <CardContent>
                  <ColorButton variant='contained'>Generate Image</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Type your text and witness it come to life as an image!
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={removeBackground}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/removebg.jpg')}
                  alt="Clear Background"
                />
                <CardContent>
                  <ColorButton variant='contained'>Clear Background</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Effortlessly remove backgrounds from any image
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={imageObservation}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/imageobservation.jpg')}
                  alt="Observe Image"
                />
                <CardContent>
                  <ColorButton variant='contained'>Observe Image</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Get detailed descriptions of your images
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={imageLocationObservation}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/imagelocation.jpg')}
                  alt="Locate Image"
                />
                <CardContent>
                  <ColorButton variant='contained'>Locate Image</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Determine potential locations based on image content
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={imageChat}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/imagequestion.jpg')}
                  alt="Inquire"
                />
                <CardContent>
                  <ColorButton variant='contained'>Inquire</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Ask questions about objects within images
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={travelBot}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/travelbot.png')}
                  alt="Start Travel Chat"
                />
                <CardContent>
                  <ColorButton variant='contained'>Start Travel Chat</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Plan your travels with personalized itineraries
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} md={4} style={{ display: 'flex' }}>
            <Card sx={{ maxHeight: 400, border: "none", boxShadow: "none" }}>
              <CardActionArea onClick={quizBot}>
                <CardMedia
                  component="img"
                  height="250"
                  image={require('../images/travelbot.png')}
                  alt="Start Quiz"
                />
                <CardContent>
                  <ColorButton variant='contained'>Start Quiz for Beta testers</ColorButton>
                  <Typography gutterBottom variant="h6" component="div">
                    Play the Quiz of your choice
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>

  );
}
