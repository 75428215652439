import React, { Component } from 'react';
import './TravelBot.css'; // Import CSS for styling

class TravelBot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            input: '',
            username: this.generateRandomUsername(),
        };
    }

    generateRandomUsername = () => {
        const length = 8;
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    handleInputChange = (e) => {
        this.setState({ input: e.target.value });
    };

    handleSendMessage = () => {
        const { input, messages, username } = this.state;
        if (input.trim() === '') return;

        // Create a new message object with the user's input
        const newUserMessage = {
            role: 'User',
            content: input,
        };

        // Add the new user message to the list of messages immediately
        const updatedMessages = [...messages, newUserMessage];
        this.setState({ messages: updatedMessages, input: '' });

        // Send the user's input to the API
        fetch('https://travel-chat-bot-i7heuxrhcq-em.a.run.app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_input: input, user_name: username }),
        })
            .then((response) => response.json())
            .then((data) => {
                // Add the bot's response to the list of messages
                const newBotMessage = {
                    role: 'TravelHelper',
                    content: data.response,
                };
                const updatedMessagesWithBotResponse = [...updatedMessages, newBotMessage];
                this.setState({ messages: updatedMessagesWithBotResponse });
            })
            .catch((error) => {
                console.error('Error:', error);
                // Handle error if needed
            });
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSendMessage();
        }
    };

    render() {
        const { messages, input } = this.state;

        return (
            <div className="TravelBot">
                <div className="chat-container">
                    <div className="chat-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.role}`}>
                                <div className={`message-card ${message.role}`}>
                                    {message.content}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="input-container">
                        <input
                            type="text"
                            value={input}
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress} // Handle Enter key press
                            placeholder="Answer a few questions and let us plan your perfect trip! So what's in your mind?"
                            className="input-field"
                        />
                        <button onClick={this.handleSendMessage} className="send-button">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TravelBot;