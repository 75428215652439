import React, { useState } from 'react'
import { FileUploader } from '../components/FileUploader';
import { Container, Card, Button, CardContent, CardMedia, Typography, Grid, CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import Backdrop from '@mui/material/Backdrop';


function RefinePage() {
  const [fileName, setFileName] = useState("");
  const [url, setURL] = useState("");
  const [refinedURL, setRefinedURL] = useState("");
  const redirectUrl = window.location.href;

  const handleFile = (file) => {
    setRefinedURL(null)
    setFileName(file.name)
    console.log(URL.createObjectURL(file))
    setURL(URL.createObjectURL(file))
    handleRefine(file)
  };

  //change file to event 
  const handleRefine = (selectedFile) => {
    console.log('refining photo now');
    console.log('in handle refine', selectedFile);
    //change url to refinedURL
    console.log(url);

    if (selectedFile) {
      const formData = new FormData();
      formData.append('img', selectedFile);

      // Make an API call here, for example using fetch or axios
      fetch('http://localhost:8080/repixel.ai/face-restoration', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Redirect-Url': redirectUrl,
        },
        body: formData,
        // Add headers if needed, e.g., authorization headers
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the API, update refinedURL if needed
          console.log('API response:', data);
          setRefinedURL(data.img); // Assuming the API returns a refined URL
        })
        .catch(error => {
          console.error('Error refining photo:', error);
        });
    } else {
      console.warn('No file selected for refining.');
    }
  };
  return (

    <Container sx={{ py: 4 }}>
      <Grid container spacing={1} align="center">
        <Grid item xs={12} md={6}>
          <Card elevation={5} variant="outlined" style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3"}}>
            {url ?
              <CardMedia
                component="img"
                height="300"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={url}
                title="Original"
              /> : null}
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                <FileUploader handleFile={handleFile} buttonLabel='Upload Photo' />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20}}>
            {fileName ?
              <CardMedia
                component="img"
                height="300"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={refinedURL ? refinedURL : url}
                title="Refined"
              /> : null}
            {fileName && !refinedURL ?
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
              : null}
            <CardContent>
            {fileName ?

              <Typography gutterBottom variant="h4" component="div">
                  <a href={refinedURL} target="_blank" download>
                    <Button variant="contained" startIcon={<GetAppIcon />}>
                      Download
                    </Button>
                  </a>
              </Typography>
               : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RefinePage