import React, { Component } from 'react';

class QuizBot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            input: '', // User input for category selection
            selectedCategory: '', // Selected category
            options: [ // Possible options for category selection
                'Bollywood Movies',
                'Hollywood Movies',
                'Bollywood Series',
                'History',
                'Geography',
                'Science',
                '5th Class Science',
                'Economics',
                '10th Class Physics',
                '12th Class Physics',
                'Chemistry',
                'Mathematics',
                'Quantitative Ability',
                '10th Class Mathematics Trignometry'
            ],
            selectedOption: '', // User-selected option
            selectedOptionValue: '',
            result: '', // Correct or Incorrect
            quizQuestion: null, // Quiz question object
            correctAnswer: '' // Correct answer to display in case of an incorrect response
        };
    }

    componentDidMount() {
        // Display initial message when the component mounts
        this.displayMessage("Welcome! Please select a category.");
    }

    handleInputChange = (e) => {
        this.setState({ input: e.target.value });
    };

    displayMessage = (message) => {
        const { messages } = this.state;
        const updatedMessages = [...messages, { role: 'Bot', content: message }];
        this.setState({ messages: updatedMessages });
    };

    handleSendMessage = () => {
        const { selectedCategory } = this.state;

        // Send the selected category to the API only if it's not empty
        if (selectedCategory.trim() !== '') {
            fetch('https://quiz-chat-bot-i7heuxrhcq-em.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_input: selectedCategory }),
            })
                .then((response) => response.json())
                .then((data) => {
                    // Extract the quiz question object from the response
                    const quizQuestion = JSON.parse(data.response);
                    // Update state with the received quiz question and reset selected option, result, and correct answer
                    this.setState({ quizQuestion, selectedOption: '', result: '', correctAnswer: '' });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    // Handle error if needed
                });
        }
    };

    handleCategorySelection = () => {
        const { input } = this.state;

        // Update selected category and send it to the API
        this.setState({ selectedCategory: input }, () => {
            this.handleSendMessage();
        });
    };

    handleOptionChange = (e) => {
        const selectedOptionValue = e.target.value;
        const optionIndex = this.state.quizQuestion ? Object.values(this.state.quizQuestion).indexOf(selectedOptionValue) : -1;
        const optionLetters = ['A', 'B', 'C', 'D'];
        const selectedOption = optionLetters[optionIndex - 1];
        console.log('Selected option:', selectedOption);
        this.setState({ selectedOption: e.target.value });
        this.setState({ selectedOptionValue: selectedOption });
    };
    
    handleCheckAnswer = () => {
        const { selectedOptionValue, quizQuestion } = this.state;

        console.log('selectedOptionValue:', selectedOptionValue);
        console.log('quizQuestion.Answer:', quizQuestion.Answer);

        if (selectedOptionValue === quizQuestion.Answer) {
            this.setState({ result: 'Correct' });
        } else {
            this.setState({ result: 'Incorrect', correctAnswer: quizQuestion.Answer });
        }

        // Fetch the next question
        this.handleSendMessage();
    };

    render() {
        const { messages, input, options, selectedOption, quizQuestion, result, correctAnswer } = this.state;

        return (
            <div className="QuizBot">
                <div className="chat-container">
                    <div className="chat-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.role}`}>
                                <div className={`message-card ${message.role}`}>
                                    {message.content}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="category-selection">
                        <select value={input} onChange={(e) => this.setState({ input: e.target.value })}>
                            <option value="">Choose a category</option>
                            {options.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                        <button onClick={this.handleCategorySelection} disabled={!input} className="submit-button">
                            Submit Category
                        </button>
                    </div>
                    <div className="quiz-container">
                        {quizQuestion && (
                            <div className="quiz-question">
                                <p>{quizQuestion.Question}</p>
                                {Object.keys(quizQuestion)
                                    .filter(key => key.startsWith("Option"))
                                    .map((key, index) => (
                                        <label key={index}>
                                            <input
                                                type="radio"
                                                value={quizQuestion[key]}
                                                checked={selectedOption === quizQuestion[key]}
                                                onChange={this.handleOptionChange}
                                            />
                                            {quizQuestion[key]}
                                        </label>
                                    ))}
                            </div>
                        )}
                        {result !== '' && (
                            <div className="result-message">
                                <p>{`Your answer is ${result}`}</p>
                                {result === 'Incorrect' && (
                                    <p>{`The correct answer is: ${correctAnswer}`}</p>
                                )}
                            </div>
                        )}
                        <button onClick={this.handleCheckAnswer} disabled={!selectedOption} className="submit-button">
                            Submit Answer
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuizBot;
