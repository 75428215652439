import React, { useState } from 'react'
import { FileUploader } from '../components/FileUploader';
import { Container, Card, Button, CardContent, CardMedia, Typography, Grid, CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';


function ImageObservation() {
	const [fileName, setFileName] = useState("");
	const [url, setURL] = useState("");
	const [imageText, setObservationText] = useState("");
	const [selectedFile, setSelectedFile] = useState(null); // new state
	const [showBackdrop, setShowBackdrop] = useState(null); 

	const handleFile = (file) => {
		setShowBackdrop(false)
		setObservationText(null)
		setFileName(file.name);
		console.log(URL.createObjectURL(file));
		setURL(URL.createObjectURL(file));
		setSelectedFile(file);
	};

	//change file to event 
	const handleTransformation = (event) => {
		setShowBackdrop(true)
		console.log('Observing Image now')
		//change url

		if (selectedFile) {
			const formData = new FormData();
			formData.append('img', selectedFile);

			// Make an API call here, for example using fetch or axios
			fetch('https://asia-south1-cloud-medley.cloudfunctions.net/image-observation', {
				method: 'POST',
				body: formData,
				// Add headers if needed, e.g., authorization headers
			})
				.then(response => response.json())
				.then(data => {
					// Handle the response from the API, update if needed
					console.log('API response:', data)
					setObservationText(data.text) // Assuming the API returns a refined URL
					setShowBackdrop(false)
				})
				.catch(error => {
					console.error('Error :', error);
				});
		} else {
			console.warn('No file selected.');
		}
	};
	return (

		<Container sx={{ py: 4 }}>
			<Grid container spacing={1} justifyContent={'center'}>
				<Grid item xs={12} md={6}>
					<Card style={{ alignItems: "center", display: "flex", flexDirection: "column", border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3"}}>
						<CardMedia
							sx={{ height: 300, width: 300}}
							image={url}
							title="Original"
						/>
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								<FileUploader handleFile={handleFile} buttonLabel='Upload Photo' />
							</Typography>
						</CardContent>
						{fileName && imageText ?
							<CardContent>
								<Typography variant="h5" component="div">
									{imageText}
								</Typography>
							</CardContent> : null}
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								{fileName && !imageText ?
									<Button variant="contained" onClick={handleTransformation}>Describe Image</Button>
									:null}
							</Typography>
							{showBackdrop?
							<Backdrop
							sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
							<CircularProgress color="inherit" />
						  </Backdrop>: null}
						</CardContent>
					</Card>
				</Grid>
				
			</Grid>
		</Container>
	)
}

export default ImageObservation