import React, { useState } from 'react';
import { Form, TextField } from '@mui/material';
import { Container, Card, Button, CardContent, CardMedia, CardActions, CircularProgress, Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { RpxlButton } from '../components/RpxlButton';


function TextToImage() {
  const [text, setText] = useState("");
  const [generatedImageURL, setGeneratedImageURL] = useState("");
	const [showBackdrop, setShowBackdrop] = useState(null); 

  const handleTextChange = (event) => {
    setShowBackdrop(false)
    setText(event.target.value)
  };

  const handleGenerateImage = () => {
    setShowBackdrop(true)
    console.log('Generating image from text');
    console.log(text);

    if (text.trim().length > 0) {
      // Make an API call here, for example using fetch or axios
      fetch('https://asia-south1-cloud-medley.cloudfunctions.net/text-to-image', {
        method: 'POST',
        body: text,
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the API, update generatedImageURL if needed
          console.log('API response:', data)
          setGeneratedImageURL(data.img) // Assuming the API returns a generated image URL
          setShowBackdrop(false)
        })
        .catch(error => {
          console.error('Error generating image from text:', error);
        });
    } else {
      console.warn('No text provided to generate image.');
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs={12} md={6}>
          <Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20}}>
            {generatedImageURL ?
              <CardMedia
                component="img"
                height="500"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={generatedImageURL}
                title="Original"
              /> : null}
            <CardContent>

              <TextField
                id="outlined-multiline-flexible"
                fullWidth
                label="Image Description"
                multiline
                maxRows={4}
                onChange={handleTextChange}
                placeholder="Enter text to generate image"
              />
            </CardContent>
            <CardActions>
              <RpxlButton variant="contained" onClick={handleGenerateImage}>Generate Image</RpxlButton>
            </CardActions>
            {showBackdrop?
							<Backdrop
							sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
							<CircularProgress color="inherit" />
						  </Backdrop>: null}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TextToImage;