import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const RpxlButton = styled(Button)(({ pill }) => ({
    borderRadius: pill ? 50 : 4,
    fontSize: 10,
    color: 'white',
    margin: '0 auto',
    backgroundColor: '#770737',
    '&:hover': {
    backgroundColor: '#770737',
  },
  }));