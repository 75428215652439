import React, { useState } from 'react'
import { FileUploader } from '../components/FileUploader';
import { Container, Card, Button, CardContent, CardMedia, Typography, Grid } from '@mui/material';

function RemoveBackground() {
	const [fileName, setFileName] = useState("");
	const [url, setURL] = useState("");
	const [photoURL, setPhotoURL] = useState("");
	const [selectedFile, setSelectedFile] = useState(null); // new state

	const handleFile = (file) => {
		setPhotoURL(null)
		setFileName(file.name);
		console.log(URL.createObjectURL(file));
		setURL(URL.createObjectURL(file));
		setSelectedFile(file);
	};

	const download = (file) => {

		console.log('downloading file');

	};
	//change file to event 
	const handleTransformation = (event) => {
		console.log('Removing Background now');
		//change url
		console.log(url);

		if (selectedFile) {
			const formData = new FormData();
			formData.append('img', selectedFile);

			// Make an API call here, for example using fetch or axios
			fetch('https://asia-south1-cloud-medley.cloudfunctions.net/remove-background', {
				method: 'POST',
				body: formData,
				// Add headers if needed, e.g., authorization headers
			})
				.then(response => response.json())
				.then(data => {
					// Handle the response from the API, update if needed
					console.log('API response:', data);
					setPhotoURL(data.img); // Assuming the API returns a refined URL
				})
				.catch(error => {
					console.error('Error :', error);
				});
		} else {
			console.warn('No file selected.');
		}
	};
	return (

		<Container sx={{ py: 4 }}>
			<Grid container spacing={1} align="center">
				<Grid item xs={6}>
					<Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3"}}>
						<CardMedia
							sx={{ height: 300, width: 200 }}
							image={url}
							title="Original"
						/>
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								<FileUploader handleFile={handleFile} buttonLabel='Upload Photo' />
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20}}>
						{fileName && photoURL ?
							<CardContent
								sx={{ height: 300, width: 200 }}
								image={photoURL}
								title="Background Removed"
							/> : null}
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								{fileName && !photoURL ?
									<Button variant="contained" onClick={handleTransformation}>Remove Background Now</Button> : null}
								{fileName && photoURL ?
									<Button variant="contained" onClick={download}>Download</Button> : null}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	)
}

export default RemoveBackground