import React, { useState } from 'react';
import { FileUploader } from '../components/FileUploader';
import { Container, Card, Button, CardContent, CardMedia, Typography, Grid, CircularProgress, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

function ImageLocationObservation() {
	const [fileName, setFileName] = useState("");
	const [url, setURL] = useState("");
	const [imageText, setObservationText] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [textInput, setTextInput] = useState("");
	const [showBackdrop, setShowBackdrop] = useState(null);
	const [askingAnythingElse, setAskingAnythingElse] = useState(false);

	const handleFile = (file) => {
		setShowBackdrop(false);
		setObservationText(null);
		setFileName(file.name);
		setURL(URL.createObjectURL(file));
		setSelectedFile(file);
		setAskingAnythingElse(false);
		setTextInput("");
	};

	const handleTransformation = (event) => {
		setShowBackdrop(true);

		if (selectedFile) {
			const formData = new FormData();
			formData.append('img', selectedFile);

			if (textInput.trim() !== "") {
				formData.append('text', textInput);
			}

			fetch('https://asia-south1-cloud-medley.cloudfunctions.net/image-location-observation', {
				method: 'POST',
				body: formData,
			})
				.then(response => response.json())
				.then(data => {
					console.log('API response:', data);
					setObservationText(data.text);
					setShowBackdrop(false);
					setAskingAnythingElse(true);
				})
				.catch(error => {
					console.error('Error:', error);
				});

		} else {
			console.warn('No file selected.');
		}
	};

	const handleAskAnythingElse = () => {
		setObservationText(null);
		setTextInput("");
		setAskingAnythingElse(false);
	};

	return (
		<Container sx={{ py: 4 }}>
			<Grid container spacing={1} justifyContent={'center'}>
				<Grid item xs={12} md={6}>
					<Card style={{ alignItems: "center", display: "flex", flexDirection: "column", border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3" }}>
						<CardMedia
							sx={{ height: 300, width: 300 }}
							image={url}
							title="Original"
						/>
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								<FileUploader handleFile={handleFile} buttonLabel='Upload Photo' />
							</Typography>
						</CardContent>
						{fileName && (imageText || askingAnythingElse) ?
							<CardContent>
								{imageText &&
									<Typography variant="h5" component="div" style={{ textAlign: 'center', margin: 'auto' }}>
										{imageText}
									</Typography>
								}
								{askingAnythingElse &&
									<div style={{ marginTop: '10px' }}>
										<Button variant="contained" onClick={handleAskAnythingElse} fullWidth>
											Want to give some suggestions
										</Button>
									</div>
								}
							</CardContent>
							:
							null
						}
						<CardContent>
							<Typography gutterBottom variant="h4" component="div">
								{fileName && !imageText && !askingAnythingElse ?
									<>
										<Grid container spacing={2} justifyContent="center" alignItems="center">
											<Grid item xs={12}>
												<TextField
													label="Input any suggestions for the location"
													value={textInput}
													onChange={(e) => setTextInput(e.target.value)}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12}>
												<Button variant="contained" onClick={handleTransformation} fullWidth>
													Describe Image
												</Button>
											</Grid>
										</Grid>
									</>
									:
									null
								}
							</Typography>
							{showBackdrop ?
								<Backdrop
									sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
									<CircularProgress color="inherit" />
								</Backdrop> : null}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}

export default ImageLocationObservation;
