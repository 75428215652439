import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { MenuItem, Chip } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';
import logoImage from '../images/logo.png';

const pages = [['Refine Pictures', '/refine'], ['Face Swap', '/swap'], ['Text to Image', 'textToImage']];

function Navbar() {
  return (
    <AppBar position="static" style={{ background: '#ff6d75' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link component={Link} to="/">
            <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} alt="Logo" src={logoImage} />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key={1} component={Link} to="/refine">
                <Typography textAlign="center">Refine Image</Typography>
              </MenuItem>
              <MenuItem key={2} component={Link} to="/swap">
                <Typography textAlign="center">Face Swap</Typography>
              </MenuItem>
              <MenuItem key={3} component={Link} to="/text-image">
                <Typography textAlign="center">Generate Image</Typography>
              </MenuItem>
              <MenuItem key={4} component={Link} to="/clear-background">
                <Typography textAlign="center">Remove Background</Typography>
              </MenuItem>
              <MenuItem key={5} component={Link} to="/desc-image">
                <Typography textAlign="center">Observe Image</Typography>
              </MenuItem>
              <MenuItem key={5} component={Link} to="/desc-location-image">
                <Typography textAlign="center">Locate Image</Typography>
              </MenuItem>
              <MenuItem key={5} component={Link} to="/image-chat">
                <Typography textAlign="center">Inquire</Typography>
              </MenuItem>
              <MenuItem key={5} component={Link} to="/travel-bot">
                <Typography textAlign="center">Start Travel Chat</Typography>
              </MenuItem>

            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Chip
              label='Refine Image'
              variant='outlined'
              component={Link} to='/refine'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Face Swap'
              variant='outlined'
              component={Link} to='/swap'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Generate Image'
              variant='outlined'
              component={Link} to='/text-image'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Remove Background'
              variant='outlined'
              component={Link} to='/clear-background'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Observe Image'
              variant='outlined'
              component={Link} to='/desc-image'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Locate Image'
              variant='outlined'
              component={Link} to='/desc-location-image'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Inquire Image'
              variant='outlined'
              component={Link} to='/image-chat'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

            <Chip
              label='Start Travel Chat'
              variant='outlined'
              component={Link} to='/travel-bot'
              clickable
              sx={{ fontSize: 18, fontWeight: 'bold', m: 1, color: 'white', display: 'block' }}
            />

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;