import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RefinePage from './pages/RefinePage';
import SwapFace from './pages/SwapFace';
import TextToImage from './pages/TextToImage';
import TextImageToImage from './pages/TextImageToImage';
import RemoveBackground from './pages/RemoveBackground';
import ImageObservation from './pages/ImageObservation';
import ImageLocationObservation from './pages/ImageLocationObservation';
import ImageChat from './pages/ImageChat';
import Home from './pages/Home';
import Login from './pages/Login';
import TravelBot from './pages/TravelBot';
import QuizBot from './pages/QuizBot';
import { CssBaseline, Typography, Box, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const newTheme = createTheme({
  fontFamily: 'Roboto, sans-serif',
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primaryColor: '#FF5733',
    secondaryColor: '#ff6d75',
  },
});

const App = () => {

  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          {/* Commenting the login flow as right now spring project isn't deployed */}
          {/* <Route path="/" element={<Login />} />  */}
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/refine" element={<RefinePage />} />
          <Route path="/swap" element={<SwapFace />} />
          <Route path="/text-image" element={<TextToImage />} />
          <Route path="/textImageToImage" element={<TextImageToImage />} />
          <Route path="/clear-background" element={<RemoveBackground />} />
          <Route path="/desc-image" element={<ImageObservation />} />
          <Route path="/desc-location-image" element={<ImageLocationObservation />} />
          <Route path="/image-chat" element={<ImageChat />} />
          <Route path="/travel-bot" element={<TravelBot />} />
          <Route path="/quiz-bot" element={<QuizBot />} />
        </Routes>
      </Router>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Repixel
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Repixel: Where Every Pixel Tells a Story!!
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cloudmedley.com/">
        CLOUDMEDLEY
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default App;
