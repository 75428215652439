import React, { useState } from 'react'
import { FileUploader } from '../components/FileUploader';
import { Container, Card, Button, CardContent, CardMedia, Typography, Grid, CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';


function SwapFace() {
  const [firstFileName, setFirstFileName] = useState("");
  const [secondFileName, setSecondFileName] = useState("");
  const [firstPictureUrl, setFirstURL] = useState("");
  const [secondPictureUrl, setSecondURL] = useState("");
  const [refinedURL, setRefinedURL] = useState("");
  const [firstSelectedFile, setFirstSelectedFile] = useState(null);
  const [secondSelectedFile, setSecondSelectedFile] = useState(null);
	const [showBackdrop, setShowBackdrop] = useState(null); 

  const handleFirstFile = (file) => {
    setShowBackdrop(false)
    setRefinedURL(null)
    setFirstFileName(file.name);
    console.log(URL.createObjectURL(file));
    setFirstURL(URL.createObjectURL(file));
    setFirstSelectedFile(file);
  };
  const handleSecondFile = (file) => {
    setShowBackdrop(false)
    setRefinedURL(null)
    setSecondFileName(file.name);
    console.log(URL.createObjectURL(file));
    setSecondURL(URL.createObjectURL(file));
    setSecondSelectedFile(file);
  }

  const handleSwap = (event) => {
    setShowBackdrop(true)
    console.log('swapping photo now');
    //change url to refinedURL
    console.log(firstPictureUrl);
    console.log(secondPictureUrl);

    if (firstSelectedFile && secondSelectedFile) {
      const formData = new FormData();
      formData.append('img1', firstSelectedFile);
      formData.append('img2', secondSelectedFile);

      // Make an API call here, for example using fetch or axios
      fetch('https://asia-south1-cloud-medley.cloudfunctions.net/swap-face', {
        method: 'POST',
        body: formData,
        // Add headers if needed, e.g., authorization headers
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the API, update refinedURL if needed
          console.log('API response:', data);
          setRefinedURL(data.img); // Assuming the API returns a refined URL
          setShowBackdrop(false)
        })
        .catch(error => {
          console.error('Error swapping photo:', error);
        });
    } else {
      console.warn('Please select both files before swapping.');
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1} align="center">
        <Grid item xs={12} md={3}>
          <Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3"}}>
            {(firstFileName) ?
              <CardMedia
                component="img"
                height="300"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={firstPictureUrl}
                title="Original"
              /> : null}
            <CardContent>
              <Typography gutterBottom variant="h7" component="div">
                <FileUploader handleFile={handleFirstFile} buttonLabel="Original Photo" />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card style={{ border: "2px dashed", borderColor: "#ff6d75", borderRadius: 20, backgroundColor: "#D3D3D3"}}>
            {(secondFileName) ?
              <CardMedia
                component="img"
                height="300"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={secondPictureUrl}
                title="Target Photo"
              /> : null}
            <CardContent>
              <Typography gutterBottom variant="h7" component="div">
                <FileUploader handleFile={handleSecondFile} buttonLabel="Target Photo" />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ border: "2px dotted", borderColor: "#ff6d75", borderRadius: 20}}>
              <CardMedia
                component="img"
                height="300"
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                image={refinedURL? refinedURL: require('../images/swap-1.jpg')}
                title="Swapped Photo"
              />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Swapped Photo
              </Typography>
              {(firstFileName && secondFileName) ?
                <Button variant="contained" onClick={handleSwap}>Swap Faces</Button> : null}                
            </CardContent>
            {showBackdrop?
							<Backdrop
							sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
							<CircularProgress color="inherit" />
						  </Backdrop>: null}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SwapFace